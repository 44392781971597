import React from 'react';
import styled, { css } from 'styled-components';
import ArrowRight from '../../../images/product-assets/arrow-right-dark.svg';
import { useModal } from '../../../context/ModalContext';

const CtaContainer = styled.div`
    display: flex;
    gap: 16px;
`;

const SharedStyle = css`
    font-family: 'Rubik', sans-serif;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 8px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s linear;
    line-height: 24px;

    > span {
        white-space: nowrap;
    }

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 20px;
    }

    @media only screen and (max-width: 560px) {
        padding: 6px 14px;
    }

    &:hover {
        transform: scale(1.08);
    }
`;

const CtaLink = styled.a`
    ${SharedStyle}
    background-color: #fff;
    color: #0c1a68;

    ${({ secondary }) =>
        secondary &&
        css`
            background-color: transparent;
            color: #fff;
        `}
`;

const CtaButton = styled.button`
    ${SharedStyle}
    background-color: transparent;
    color: #fff;

    ${({ primary }) =>
        primary &&
        css`
            background-color: #fff;
            color: #0c1a68;
        `}
`;
export const IFPBannerCta = () => {
    const { openModal } = useModal();
    return (
        <CtaContainer>
            <CtaButton primary type="button" onClick={() => openModal('contact')}>
                <span>Schedule a Demo</span>
                <img src={ArrowRight} alt="arrow-right" />
            </CtaButton>
            <CtaLink secondary href="https://hubs.la/Q02TXSR40" target="_blank" rel="noreferrer">
                <span>Integrate Today</span>
            </CtaLink>
        </CtaContainer>
    );
};
export const KycAmlBannerCta = () => {
    const { openModal } = useModal();
    return (
        <CtaContainer>
            <CtaLink
                href="https://portal.authenticate.com/register?utm_source=solutions&utm_medium=kyc_aml&utm_campaign=banner"
                target="_blank"
                rel="noreferrer"
            >
                <span>Get Started</span>
                <img src={ArrowRight} alt="arrow-right" />
            </CtaLink>
            <CtaButton type="button" onClick={() => openModal('contact')}>
                <span>Schedule a Demo</span>
            </CtaButton>
        </CtaContainer>
    );
};

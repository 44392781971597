import React from 'react';
import styled from 'styled-components';
import ArrowRight from '../../../images/product-assets/chevron-right.svg';
import { formatText } from '../../../utils';
import ShieldIcon from '../../../images/product-assets/protection-shield.svg';
import { Link } from 'gatsby';

const Wrapper = styled.section`
    padding-top: 110px;

    @media only screen and (max-width: 850px) {
        padding-top: 72px;
    }

    @media only screen and (max-width: 560px) {
        padding-top: 54px;
    }
`;

const Container = styled.div`
    max-width: 1280px;
    margin: auto;
    padding: 0 24px;
    display: flex;

    @media only screen and (max-width: 560px) {
        flex-direction: column;
    }
`;

const LeftSection = styled.div`
    flex: 0.8;

    @media only screen and (max-width: 850px) {
        flex: 0.7;
    }
`;

const Heading = styled.h1`
    font-size: 36px;
    line-height: 42.66px;
    color: #1d2939;
    font-weight: 500;

    @media only screen and (max-width: 850px) {
        font-size: 24px;
        line-height: 28.44px;
    }

    @media only screen and (max-width: 560px) {
        text-align: center;
    }
`;

const Para = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    color: #1d2939;
    max-width: 410px;
    padding: 20px 0 32px 0;

    @media only screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 16.59px;
    }

    @media only screen and (max-width: 560px) {
        max-width: 100%;
        text-align: center;
        line-height: 14.22px;
        font-size: 12px;
        padding: 12px 0 32px 0;
    }
`;
const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid #1d2939;
    border-radius: 4px;
    width: max-content;
    cursor: pointer;
    text-decoration: none;

    span {
        font-size: 16px;
        color: #1d2939;
        text-decoration: none;
    }

    &:hover {
        img {
            transition: all 0.2s ease-in-out;
            transform: translateX(4px);
        }
    }

    @media only screen and (max-width: 560px) {
        margin: auto;

        span {
            font-size: 14px;
        }

        img {
            height: 18px;
            width: 18px;
        }
    }
`;

const RightSection = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 32px 10px 32px 80px;

    @media only screen and (max-width: 850px) {
        padding: 0 0 0 58px;
    }

    @media only screen and (max-width: 560px) {
        padding: 36px 0 0 0;
    }
`;

const GridContainer = styled.div``;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;

    > div:first-child {
        padding-right: 40px;
    }
    > div:last-child {
        padding-left: 40px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
        background-color: #eaecf0;
    }

    @media only screen and (max-width: 850px) {
        > div:first-child {
            padding-right: 24px;
        }

        > div:last-child {
            padding-left: 24px;
        }
    }

    @media only screen and (max-width: 560px) {
        > div:first-child {
            padding-right: 12px;
        }

        > div:last-child {
            padding-left: 12px;
        }
    }
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;

    > div:first-child {
        padding-right: 40px;
    }

    > div:last-child {
        padding-left: 40px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
        background-color: #eaecf0;
    }

    @media only screen and (max-width: 850px) {
        > div:first-child {
            padding-right: 24px;
        }

        > div:last-child {
            padding-left: 24px;
        }
    }

    @media only screen and (max-width: 560px) {
        > div:first-child {
            padding-right: 12px;
        }

        > div:last-child {
            padding-left: 12px;
        }
    }
`;

const ComplianceItem = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
    padding: 11px 0;
    height: 46px;

    @media only screen and (max-width: 560px) {
        gap: 8px;
        padding: 0;
    }
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
`;

const TextContainer = styled.div``;

const Text = styled.p`
    font-size: 16px;
    font-weight: 400;
    color: #1d2939;

    @media only screen and (max-width: 850px) {
        font-size: 14px;
    }
`;

const Highlight = styled.span`
    color: #12b76a;
`;

const Hr = styled.div`
    height: 1px;
    width: 95%;
    background-color: #eaecf0;
    margin: 32px 0;

    @media only screen and (max-width: 850px) {
        margin: 20px 0;
    }

    @media only screen and (max-width: 560px) {
        margin: 12px 0;
    }
`;
const PrivacyProtections = () => {
    return (
        <Wrapper>
            <Container>
                <LeftSection>
                    <Heading>Industry-Leading Compliance and Privacy Protection</Heading>
                    <Para>
                        {formatText(
                            'Authenticate® adheres to the highest industry standards, ensuring full compliance and certification.'
                        )}
                    </Para>
                    <StyledLink to="/resources/security-and-compliance">
                        <span>Learn How</span>
                        <Icon src={ArrowRight} alt="arrow-right" />
                    </StyledLink>
                </LeftSection>
                <RightSection>
                    <GridContainer>
                        <Top>
                            <ComplianceItem>
                                <Icon src={ShieldIcon} alt="shield icon" />
                                <TextContainer>
                                    <Text>
                                        SOC 2 Type 2 and HIPAA Compliance{' '}
                                        <Highlight>Certified</Highlight>
                                    </Text>
                                </TextContainer>
                            </ComplianceItem>
                            <ComplianceItem>
                                <Icon src={ShieldIcon} alt="shield icon" />
                                <TextContainer>
                                    <Text>
                                        DPPA and GLBA <Highlight>Compliant</Highlight>
                                    </Text>
                                </TextContainer>
                            </ComplianceItem>
                        </Top>
                        <Hr />
                        <Bottom>
                            <ComplianceItem>
                                <Icon src={ShieldIcon} alt="shield icon" />
                                <TextContainer>
                                    <Text>
                                        GDPR and CCPA <Highlight>Compliant</Highlight>
                                    </Text>
                                </TextContainer>
                            </ComplianceItem>
                            <ComplianceItem>
                                <Icon src={ShieldIcon} alt="shield icon" />
                                <TextContainer>
                                    <Text>
                                        PCI DSS <Highlight>Compliant</Highlight>
                                    </Text>
                                </TextContainer>
                            </ComplianceItem>
                        </Bottom>
                    </GridContainer>
                </RightSection>
            </Container>
        </Wrapper>
    );
};

export default PrivacyProtections;

import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { formatText } from '../../../utils';
import ARROW_DOWN from '../../../images/down_arrow_white.svg';
import PropTypes from 'prop-types';

const Accordion = styled.div`
    width: 100%;
    background-color: #1d1f33;
    border-radius: 12px;
    overflow: hidden;
`;

const Top = styled.div`
    display: flex;
    align-content: center;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

const Data = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 20px;

    @media screen and (max-width: 850px) {
        padding: 17.5px 12px;
    }

    @media screen and (max-width: 560px) {
        padding: 14px;
    }
`;

const IconContainer = styled.div`
    background-color: #fff;
    height: 100%;
    padding: 0 19px;
    display: grid;
    place-items: center;

    @media screen and (max-width: 850px) {
        padding: 0 14px;
    }
`;

const Icon = styled.img`
    transition: transform 0.3s ease-in-out;
    ${({ iconType, isOpen }) => {
        if (iconType === 'arrow' && isOpen) {
            return css`
                transform: rotate(180deg);
            `;
        }
    }}
`;

const Text = styled.div`
    font-size: 20px;
    line-height: 23.7px;
    color: #fff;
    font-weight: 400;

    @media screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 16.59px;
    }
`;

const ArrowContainer = styled.div``;

const Bottom = styled.div`
    overflow: hidden;
    height: ${(props) => (props.isOpen ? `${props.height}px` : '0')};
    transition: height 0.3s ease-in-out;
`;

const Description = styled.p`
    padding: 20px;
    font-size: 16px;
    color: #fff;
    line-height: 22.4px;

    @media screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 16.8px;
        padding: 12px;
    }
`;

const CustomAccordion = ({ data, isOpen, toggleAccordion }) => {
    const containerRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (isOpen && containerRef?.current) {
            setHeight(containerRef?.current?.scrollHeight);
        }
    }, [isOpen]);

    return (
        <Accordion>
            <Top>
                <Header onClick={toggleAccordion}>
                    <IconContainer>
                        <Icon src={data.icon} alt="icon" />
                    </IconContainer>
                    <Data>
                        <Text>{data.Title}</Text>
                        <ArrowContainer>
                            <Icon
                                src={ARROW_DOWN}
                                alt="arrow-icon"
                                height={24}
                                width={24}
                                isOpen={isOpen}
                                iconType="arrow"
                            />
                        </ArrowContainer>
                    </Data>
                </Header>
            </Top>
            <Bottom ref={containerRef} isOpen={isOpen} height={height}>
                <Description>{formatText(data.desc)}</Description>
            </Bottom>
        </Accordion>
    );
};

CustomAccordion.propTypes = {
    data: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleAccordion: PropTypes.func.isRequired,
};

export default CustomAccordion;

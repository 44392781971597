import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BACKGROUND from '../../../images/solutions-gradient-bg.png';

const Container = styled.div`
    max-width: 1280px;
    margin: auto;
    padding: 0 44px;
    overflow: hidden;

    @media only screen and (max-width: 850px) {
        padding: 0 24px;
    }

    @media only screen and (max-width: 560px) {
        padding: 0 16px;
    }
`;
const Box = styled.div`
    background: url(${BACKGROUND}) no-repeat center center/cover;
    margin: auto;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #eaecf0;
    overflow: hidden;
    padding: 36px;
    display: flex;
    margin-top: 54px;
    transform-origin: center;
    transition: transform 0.1s ease-out;

    @media only screen and (max-width: 850px) {
        margin-top: 30px;
        padding: 24px;
    }

    @media only screen and (max-width: 560px) {
        flex-direction: column;
        padding: 16px;
        border-radius: 16px;
        margin-top: 22px;
    }
`;

const LeftContent = styled.div`
    flex: 1;
`;

const Heading = styled.h1`
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    color: #fff;
    max-width: 434px;

    @media only screen and (max-width: 850px) {
        font-size: 24px;
        line-height: 27.5px;
        max-width: 270px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const RightContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 36px;

    @media only screen and (max-width: 850px) {
        gap: 24px;
    }
`;

const Para = styled.p`
    font-size: 18px;
    line-height: 27px;
    color: #fff;

    @media only screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 21px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 12px;
        line-height: 16px;
        padding-top: 12px;
    }
`;

const Banner = ({ heading, subText, ctaComponent }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const banner = containerRef.current;
            const rect = banner.getBoundingClientRect(); //: This method returns a DOMRect object with properties that describe the size and position of the element.
            const windowHeight = window.innerHeight;

            const startFade = windowHeight;
            const endFade = 0;

            const progress = Math.min(Math.max((rect.top - endFade) / (startFade - endFade), 0), 1);

            const scaleValue = 1 + progress * 0.06;
            banner.style.transform = `scale(${scaleValue})`;
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Container ref={containerRef}>
            <Box>
                <LeftContent>
                    <Heading>{heading}</Heading>
                </LeftContent>
                <RightContent>
                    <Para>{subText}</Para>
                    {ctaComponent}
                </RightContent>
            </Box>
        </Container>
    );
};

Banner.propTypes = {
    heading: PropTypes.string.isRequired,
    subText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    ctaComponent: PropTypes.node.isRequired,
};
export default Banner;
